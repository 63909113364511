<script lang="ts">
  import { closeModal } from '$lib/utils/modals';
	import { clickOutside } from '$lib/events/clickOutside';
	import { Icon } from '$lib/components/atoms/Icon';

  export let isOpen: boolean = false;
  export let title: string | null = null;
  export let message: string | null = null;
  export let icon: string | null = null;
</script>

{#if isOpen}
  <div
    class="modal tooltip-modal"
    role="dialog"
  >
    <div
      class="modal-dialog"
      use:clickOutside
      on:click_outside={closeModal}
    >
      <div class="modal-header">
        <div>
          <button
            class="close"
            aria-label="Chiudi"
            on:click={closeModal}
          >
            <Icon icon="close" />
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="modal-icon-title-container">
          {#if icon}
            <Icon class="modal-icon" {icon} />
          {/if}
          {#if title}
            <span class="modal-title">{@html title}</span>
          {/if}
        </div>
        {#if message}
          <p>{@html message}</p>
        {/if}
      </div>
    </div>
  </div>
{/if}

<style type="text/scss" lang="scss" scoped>
	.modal {
		@apply fixed inset-0;
    @apply flex flex-col;
		@apply md:items-center md:justify-center;
    @apply z-modal;
	}
	.modal-dialog {
		@apply bg-white;
    @apply h-full;
    @apply flex flex-col;
    @apply w-full sm:max-w-md;
    @apply md:h-auto md:max-h-[90vh];
		@apply relative;
		@apply rounded md:rounded-lg;
		@apply shadow-xl;
		@apply p-6;
		@apply transform transition-all;
		@apply sm:my-8 sm:align-middle;
	}
	.modal-header {
		@apply flex items-start justify-end;
    @apply bg-white;
	}
  .modal-icon-title-container {
    @apply flex items-center justify-center;
    @apply mb-3;
  }
	.modal-title {
		@apply text-base md:text-lg text-center;
		@apply leading-tight font-medium;
		@apply text-gray-900;
    @apply ml-3;
	}
	.modal-body {
		@apply flex-grow;
    @apply overflow-y-auto;
	}
	.close {
    @apply ml-auto;
    @apply transition-opacity duration-200;
    @apply hover:opacity-75;
	}
</style>
