<script lang="ts">
  import { onMount } from 'svelte';
  import { openModal } from "$lib/utils/modals";
  // Components
  import { Icon } from '$lib/components/atoms/Icon';
  import { TooltipModal } from '$lib/components/organisms/Modal';

  export let position: 'top-left' | 'top' | 'top-right' | 'left' | 'right' | 'bottom-left' | 'bottom' | 'bottom-right'  = 'top';
  export let title: string = '';
  export let message: string = '';
  export let icon: string | null = null;

  let tooltipMessage: string = '';
  let timer: any;
  const messageLengthToShow: number = 380;
  const messageLengthToCrop: number = 250;

  $: showTooltip = false;
  $: showReadMore = false;
  $: open = false;

  onMount(() => {
    tooltipMessage = message.replace(/<br\s*\/?>/gi, ' ');
    if (tooltipMessage && (tooltipMessage.length >= messageLengthToShow)) {
      showReadMore = true;
      for (let i = messageLengthToCrop; i < tooltipMessage.length; i ++) {
        if (tooltipMessage[i] === ' ') {
          tooltipMessage = tooltipMessage.substring(0, i) + '...'
        }
      }
    }
  });

  const startTimer = () => {
    timer = setTimeout(() => {
      showTooltip = true
    }, 300)
  }

  const onClickHandler = () => {
    open = true;
  };
</script>
<div
  class="tooltip"
  on:click={() => openModal(TooltipModal, { title, message, icon })}
>
  <span
    class="tooltip-info"
    on:mouseenter={()=>{ startTimer() } }
    on:mouseleave={()=>{ clearTimeout(timer) } }
  >
    <Icon icon="info" size="middle" />
  </span>
  {#if tooltipMessage && tooltipMessage.length > 0}
    <div class={`tooltip-container ${showTooltip ? `inline-block ${position}` : 'hidden'}`}
      on:mouseleave={() =>{ showTooltip = false; } }
    >
      <div class={`tooltip-message ${position}`}>
        <div>{@html tooltipMessage}</div>
        {#if showReadMore}
          <p on:click={() => openModal(Modal, { title, message })}>Leggi tutto</p>
        {/if}
      </div>
    </div>
  {/if}
</div>

<style type="text/scss" lang="scss" scoped>
  .tooltip {
    @apply relative;
    @apply inline-flex;

    .tooltip-info {
      @apply flex;
      @apply text-petrol;
      @apply cursor-pointer;
    }

    .tooltip-container {
      @apply absolute;
      z-index: 100;
      width: 280px;
      height: 100px;

      &.top-left {
        transform: translate(-93%, -120%);
        top: 40px;
      }
      &.top {
        transform: translate(-48%, -120%);
        top: 40px;
      }
      &.top-right {
        transform: translate(0, -120%);
        top: 40px;
      }
      &.left {
        transform: translate(-93%, -60%);
      }
      &.right {
        transform: translate(0, -60%);
      }
      &.bottom-left {
        transform: translate(-93%, 120%);
        bottom: 40px;
      }
      &.bottom {
        transform: translate(-48%, 120%);
        bottom: 40px;
      }
      &.bottom-right {
        transform: translate(0, 120%);
        bottom: 40px;
      }

      .tooltip-message {
        @apply absolute;
        @apply w-full;
        @apply text-white text-sm;
        @apply font-normal;
        @apply normal-case;
        @apply p-3;
        @apply rounded;
        background-color: #666;

        &.top-left {
          bottom: 20px;
        }
        &.top {
          bottom: 20px;
        }
        &.top-right {
          bottom: 20px;
        }
        &.left {
          right: 20px;
        }
        &.right {
          left: 20px;
        }
        &.bottom-left {
          top: 20px;
        }
        &.bottom {
          top: 20px;
        }
        &.bottom-right {
          top: 20px;
        }

        p {
          @apply text-gray-300 text-xs text-right;
          @apply mt-3;
          @apply cursor-pointer;
        }
      }
    }
  }

  .modal-icon-title-container {
    @apply flex items-center justify-center;
    @apply mb-3;

    .modal-title {
      @apply text-black text-base md:text-lg text-center;
    }
  }

</style>
