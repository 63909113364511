<script lang="ts">
  import classnames from 'classnames';
  // Components
  import { Icon } from '$lib/components/atoms/Icon';
  // import { Modal } from '$lib/components/organisms/Modal';

  export let icon: string | null = null;
  export let title: string = '';
  export let message: string = '';

  $: open = false;

  const onClickHandler = () => {
    open = true;
  };
</script>


<span class={classnames('tooltip', $$props.class)} on:click|preventDefault|stopPropagation={onClickHandler}>
  <slot></slot>
</span>
<!-- <Modal bind:open on:close={() => (open = false)} showCloseButton compact>
  <slot name="modalBody">
    {#if icon || title}
      <span class="flex items-center mb-3">
        {#if icon}
          <Icon class="modal-icon" {icon} />
        {/if}

        {#if title}
          <span class="modal-title">{title}</span>
        {/if}
      </span>
    {/if}

    {@html message}
  </slot>
</Modal> -->

<style type="text/scss" lang="scss" scoped>
  .tooltip {
    @apply flex;
  }
  .modal-title {
    @apply text-black text-base md:text-lg ml-2;
  }

  .modal-icon {
    @apply text-black;
  }
</style>
